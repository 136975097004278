@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .h1 {
    @apply tracking-sm;
    @apply text-h1-sm;
    @apply leading-h1-sm;

    @apply md:text-h1-md;
    @apply md:leading-h1-md;

    @apply lg:tracking-lg;
    @apply xl:text-h1-xl;
    @apply xl:leading-h1-xl;
  }

  .h2 {
    @apply tracking-sm;
    @apply text-h2-sm;
    @apply leading-h2-sm;

    @apply md:text-h2-md;
    @apply md:leading-h2-md;

    @apply xl:text-h2-xl;
    @apply xl:leading-h2-xl;
  }

  .h3 {
    @apply tracking-sm;
    @apply text-h3-sm;
    @apply leading-h3-sm;

    @apply md:text-h3-md;
    @apply md:leading-h3-md;

    @apply xl:text-h3-xl;
    @apply xl:leading-h3-xl;
  }

  .body {
    @apply tracking-sm;
    @apply text-body;
    @apply leading-body;
  }

  .p1 {
    @apply tracking-sm;
    @apply text-p1;
    @apply leading-p1;
  }

  .p2 {
    @apply tracking-sm;
    @apply text-p2;
    @apply leading-p2;
  }

  .container {
    @apply px-2sm;
    @apply mx-auto;
    @apply w-full;
    @apply max-w-full;
    @apply md:w-container-md;
    @apply lg:w-container-lg;
    @apply xl:w-container-xl;
    @apply xl:px-sm;
  }

  .carousel-container {
    @apply overflow-hidden;
    @apply mx-auto;
    @apply w-full;
    @apply max-w-full;
    @apply lg:px-2sm;
    @apply md:w-container-md;
    @apply lg:w-container-lg;
    @apply xl:w-container-xl;
    @apply xl:px-sm;
  }
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Regular.woff2') format('woff2'), url('/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-SemiBold.woff2') format('woff2'), url('/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Caveat';
  src: url('/fonts/Caveat-Bold.woff2') format('woff2'), url('/fonts/Caveat-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Caveat-Regular';
  src: url('/fonts/Caveat-Regular.woff2') format('woff2'), url('/fonts/Caveat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/**
 * 1. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; /* 1 */
  box-sizing: border-box;
}

body {
  @apply body;
  @apply text-navy;
  font-family: 'Montserrat', sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
body > div:first-child,
div#__next {
  height: 100%;
  min-height: 100%;
}

#__next {
  isolation: isolate;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
}

button {
  background: transparent;
  border: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

input,
textarea {
  @apply text-navy;
  outline: none;
  border: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background-color: transparent;

  &:disabled {
    /* safari disabled color must be set or it's practically invisible */
    -webkit-text-fill-color: #d6e1e4;
  }
}

/* Popup component global css */
.popup-arrow {
  @apply text-dark-navy;
  @apply bg-transparent !important;
}

/* Nprogress library global css */
#nprogress {
  height: 2px;
  position: fixed;
  top: 0px;
  z-index: 10000;
  width: 100%;
  pointer-events: none;
  .bar {
    height: 100%;
    @apply bg-light-green;
  }
}

/* This is for hiding the Zendesk Widget by default*/
iframe[title='Button to launch messaging window'],
iframe[title='Message from company'],
iframe[title='Number of unread messages'] {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
